<template>
  <div>
    <ul class="list-group">
      <li class="list-group-item list-group-item-secondary">
        <h2>Members</h2>
      </li>
      <li class="list-group-item" v-bind:key="'member-' + index" v-for="(member, index) in membersLocal">
        <div class="d-flex w-100 justify-content-between">
          <p class="font-weight-bold" v-if="member.company_name=='' || member.company_name==null">
            {{ member.first_name }} {{ member.middle_name }} {{ member.last_name }} {{ member.suffix }}
          </p>
          <p class="font-weight-bold" v-if="member.company_name!=''">
            {{ member.company_name }}
          </p>
          <small class="font-weight-bold" v-if="member.manager">
            Manager
            <button type="button" class="btn btn-danger btn-sm"  @click="removeMember(index)"> Remove</button>
          </small>
          <small class="font-weight-bold" v-if="!member.manager">
            Member
            <button type="button" class="btn btn-danger btn-sm"  @click="removeMember(index)"> Remove</button>
          </small>
        </div>
        <ContactShared v-if="contactShared" :contact="member" :contactShared="contactShared" />
        <div class="row">
          <div class="col-md-12">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="gridCheck"
                  :checked="member.manager"
                  @input="updateMember(index, 'manager', $event.target.checked)"
                />
                <label class="form-check-label" for="gridCheck">
                  <dt>Is Manager</dt>
                </label>
              </div>
            </div>
            <div class="col-md-6">
              <dt>Company Name</dt>
              <dd><input class="form-control" :value="member.company_name" @input="updateMember(index, 'company_name', $event.target.value)"/></dd>
            </div>
        </div>
        <div class="row">
        <div class="col-md-6">
          <dt>First Name</dt>
          <dd><input class="form-control" :value="member.first_name" @input="updateMember(index, 'first_name', $event.target.value)"/></dd>
        </div>
        <div class="col-md-6">
          <dt>Middle Name</dt>
          <dd><input class="form-control" :value="member.middle_name" @input="updateMember(index, 'middle_name', $event.target.value)"/></dd>
        </div>
        <div class="col-md-6">
          <dt>Last Name</dt>
          <dd><input class="form-control" :value="member.last_name" @input="updateMember(index, 'last_name', $event.target.value)"/></dd>
        </div>
        <div class="col-md-6">
          <dt>Suffix</dt>
          <select class="form-control" :value="member.suffix" @input="updateMember(index, 'suffix', $event.target.value)">
            <option disabled>Select a suffix</option>
            <option value="">None</option>
            <option>DDS</option>
            <option>MD</option>
            <option>PhD</option>
            <option>Jr.</option>
            <option>Sr.</option>
            <option>I</option>
            <option>II</option>
            <option>III</option>
            <option>IV</option>
            <option>V</option>
            <option>VI</option>
          </select>
        </div>
        </div>
        <div class="row">
        <div class="col-md-6">
          <dt>Phone</dt>
          <dd><input class="form-control" type="tel" :value="member.phone" @input="updateMember(index, 'phone', $event.target.value)"/></dd>
        </div>
        <div class="col-md-6">
          <dt>Email</dt>
          <dd><input class="form-control" :value="member.email" @input="updateMember(index, 'email', $event.target.value)"/></dd>
        </div>
        </div>
        <div class="row">
        <div class="col-md-6">
          <dt>Address 1</dt>
          <dd><input class="form-control" :value="member.address_1" @input="updateMember(index, 'address_1', $event.target.value)"/></dd>
        </div>
        <div class="col-md-6">
          <dt>Address 2</dt>
          <dd><input class="form-control" :value="member.address_2" @input="updateMember(index, 'address_2', $event.target.value)"/></dd>
        </div>
        </div>
        <div class="row">
        <div class="col-md-6">
          <dt>City</dt>
          <dd><input class="form-control" :value="member.city" @input="updateMember(index, 'city', $event.target.value)"/></dd>
        </div>
        <div class="col-md-6">
          <dt>State</dt>
          <dd><input class="form-control" :value="member.state" @input="updateMember(index, 'state', $event.target.value)"/></dd>
        </div>
        <div class="col-md-6">
          <dt>Zip</dt>
          <dd><input class="form-control" :value="member.zip" @input="updateMember(index, 'zip', $event.target.value)"/></dd>
        </div>
        <div class="col-md-6">
          <dt>Country</dt>
          <dd><input class="form-control" :value="member.country" @input="updateMember(index, 'country', $event.target.value)"/></dd>
        </div>
        </div>
      </li>

      <button @click="addMember" type="button" class="btn btn-primary btn-square btn-block">+ Add Member</button>
      <!-- Managers -->
      <li class="list-group-item list-group-item-secondary"><h2>Managers</h2></li>
      <template v-if="managers">
        <li class="list-group-item" v-bind:key="'manager-' + index" v-for="(manager, index) in managersLocal">
          <div class="d-flex w-100 justify-content-between">
            <p class="font-weight-bold" v-if="manager.company_name=='' || manager.company_name==null">
              {{ manager.first_name }} {{ manager.middle_name }} {{ manager.last_name }} {{ manager.suffix }}
            </p>
            <p class="font-weight-bold" v-if="manager.company_name!=''">
              {{ manager.company_name }}
            </p>
            <small class="font-weight-bold">
              Manager
              <button type="button" class="btn btn-danger btn-sm"  @click="removeManager(index)"> Remove</button>
            </small>
          </div>
          <ContactShared v-if="contactShared" :contact="manager" :contactShared="contactShared" />
          <div class="row">
            <div class="col-md-6">
              <dt>Company Name</dt>
              <dd><input class="form-control" :value="manager.company_name" @input="updateManager(index, 'company_name', $event.target.value)"/></dd>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <dt>First Name</dt>
              <dd><input class="form-control" :value="manager.first_name" @input="updateManager(index, 'first_name', $event.target.value)"/></dd>
            </div>
            <div class="col-md-6">
              <dt>Middle Name</dt>
              <dd><input class="form-control" :value="manager.middle_name" @input="updateManager(index, 'middle_name', $event.target.value)"/></dd>
            </div>
            <div class="col-md-6">
              <dt>Last Name</dt>
              <dd><input class="form-control" :value="manager.last_name" @input="updateManager(index, 'last_name', $event.target.value)"/></dd>
            </div>
            <div class="col-md-6">
              <dt>Suffix</dt>
              <select class="form-control" :value="manager.suffix" @input="updateManager(index, 'suffix', $event.target.value)">
                <option disabled>Select a suffix</option>
                <option value="">None</option>
                <option>DDS</option>
                <option>MD</option>
                <option>PhD</option>
                <option>Jr.</option>
                <option>Sr.</option>
                <option>I</option>
                <option>II</option>
                <option>III</option>
                <option>IV</option>
                <option>V</option>
                <option>VI</option>
              </select>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <dt>Phone</dt>
              <dd><input class="form-control" type="tel" :value="manager.phone" @input="updateManager(index, 'phone', $event.target.value)"/></dd>
            </div>
            <div class="col-md-6">
              <dt v-bind:class="{'text-danger': !validateEmail(manager.email)}">Email</dt>
              <dd><input class="form-control" :value="manager.email" @input="updateManager(index, 'email', $event.target.value)"/></dd>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <dt>Address 1</dt>
              <dd><input class="form-control" :value="manager.address_1" @input="updateManager(index, 'address_1', $event.target.value)"/></dd>
            </div>
            <div class="col-md-6">
              <dt>Address 2</dt>
              <dd><input class="form-control" :value="manager.address_2" @input="updateManager(index, 'address_2', $event.target.value)"/></dd>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <dt>City</dt>
              <dd><input class="form-control" :value="manager.city" @input="updateManager(index, 'city', $event.target.value)"/></dd>
            </div>
            <div class="col-md-6">
              <dt>State</dt>
              <dd><input class="form-control" :value="manager.state" @input="updateManager(index, 'state', $event.target.value)"/></dd>
            </div>
            <div class="col-md-6">
              <dt>Zip</dt>
              <dd><input class="form-control" :value="manager.zip" @input="updateManager(index, 'zip', $event.target.value)"/></dd>
            </div>
            <div class="col-md-6">
              <dt>Country</dt>
              <dd><input class="form-control" :value="manager.country" @input="updateManager(index, 'country', $event.target.value)"/></dd>
            </div>
          </div>
        </li>
      </template>
      <button @click="addManager" type="button" class="btn btn-primary btn-square btn-block">+ Add Manager</button>

    </ul>
  </div>
</template>
<script>

import { inputValidationsMixin } from '../../mixins';
import ContactShared from '@/components/common/ContactShared';

export default {
  name: 'MemberManagerEdit',

  components: {
    ContactShared
  },

  mixins: [inputValidationsMixin],

  props: ['members', 'managers', 'contactShared'],

  computed: {
    membersLocal() {
      return this.members ? this.members : []
    },
    managersLocal() {
      return this.managers ? this.managers : []
    }
  },

  methods: {
    emitMembers() {
      this.$emit('update:members', this.membersLocal);
    },
    emitManagers() {
      this.$emit('update:managers', this.managersLocal);
    },
    addMember() {
      if (this.membersLocal === null) {
        this.membersLocal = [];
      }
      this.membersLocal.push({});
      this.emitMembers();
    },
    removeMember(index) {
      this.membersLocal.splice(index, 1);
      this.emitMembers();
    },
    updateMember(index, key, value) {
      this.membersLocal[index] = { ...this.membersLocal[index], [key]: value }
      this.emitMembers();
    },
    addManager() {
      if (this.managersLocal === null) {
        this.managersLocal = [];
      }
      this.managersLocal.push({});
      this.emitManagers();
    },
    removeManager(index) {
      const deletedManagers = this.managersLocal.splice(index, 1);

      // If a manager is removed, ensure they are not a member with the manager boolean set to true.
      // This prevents a bug in zenapi from recreating the manager attempting to be removed.
      if (deletedManagers && deletedManagers.length > 0 && this.membersLocal && this.membersLocal.length > 0) {
        deletedManagers.forEach((deletedManager) => {
          this.membersLocal.forEach((member, i) => {
            if (member.contact_uuid === deletedManager.contact_uuid) {
              this.membersLocal[i].manager = false;
            }
          })
        });
      }

      this.emitManagers();
    },
    updateManager(index, key, value) {
      this.managersLocal[index] = { ...this.managersLocal[index], [key]: value }
      this.emitManagers();
    }
  }
};
</script>
<style lang="scss" scoped>
  .list-group {
    margin-top: 15px;
  }
  h2 {
    font-size: 10pt;
  }
  .list-group-item-secondary{
    background-color: #a9a9a9;
    color: #ffffff;
  }
  .btn-square {
    border-radius: 0px;
  }
</style>
