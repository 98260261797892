<template>
  <div class="box">
    <div class="row">
      <div class="col-md-8">
        <dt>Address 1</dt>
        <div><label v-if="!address1IsRequired" class="invalid-input-message">Address_1 is required.</label></div>
        <dd><input class="form-control" :value="renderAddress1" @input="update('address_1', $event.target.value)"/></dd>
      </div>
      <div class="col-md-8">
        <dt>Address 2</dt>
        <dd><input class="form-control" :value="renderAddress2" @input="update('address_2', $event.target.value)"/></dd>
      </div>
      <div class="col">
        <dt>Phone</dt>
        <dd><input class="form-control" :value="value.phone" @input="update('phone', $event.target.value)"/></dd>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <dt>City</dt>
        <div><label v-if="!cityIsRequired" class="invalid-input-message">City is required.</label></div>
        <dd><input class="form-control" :value="value.city" @input="update('city', $event.target.value)"/></dd>
      </div>
      <div class="col">
        <dt>State (two-letter code)</dt>
        <div><label v-if="!stateIsValid" class="invalid-input-message">Please use the two-letter uppercase state code.</label></div>
        <dd><input class="form-control" :value="value.state" @input="update('state', $event.target.value)"/></dd>
      </div>
      <div class="col">
        <dt>Zip</dt>
        <div><label v-if="!zipIsValid" class="invalid-input-message">Zip Code is required or invalid.</label></div>
        <dd><input class="form-control" :value="value.zip" @input="update('zip', $event.target.value)"/></dd>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <dt>County</dt>
        <dd><input class="form-control" :value="value.county" @input="update('county', $event.target.value)"/></dd>
      </div>
      <div class="col-md-4">
        <dt>Country</dt>
        <div><label v-if="!countryIsValid" class="invalid-input-message">Please enter a country.</label></div>
        <dd><input class="form-control" :value="value.country" @input="update('country', $event.target.value)"/></dd>
      </div>
      <div class="col-md-4">
        <dt v-bind:class="{'text-danger': !validateEmail(value.email)}">Email</dt>
        <dd><input class="form-control" :value="value.email" @input="update('email', $event.target.value)"/></dd>
      </div>
    </div>
  </div>
</template>
<script>


import { inputValidationsMixin } from '../../mixins';

export default {
  name: 'AddressEdit',

  mixins: [inputValidationsMixin],

  props: ['value'],

  computed: {
    renderAddress1() {
      if (typeof this.value.address_1 !== "undefined" ) 
        return this.value.address_1 ;
      else
        return this.value.address_line1;
    },
    renderAddress2() {
      if (typeof this.value.address_2 !== "undefined" ) 
        return this.value.address_2 ;
      else
        return this.value.address_line2;
    }, 
    address1IsRequired () {
      return (Boolean(this.renderAddress1));
    },
    cityIsRequired() {
      return (Boolean(this.value.city));
    },
    stateIsValid() {
      return (Boolean(this.value.state)) && (this.value.state === this.value.state.toUpperCase()) && (this.value.state.length === 2);
    },
    zipIsValid() {
      return (Boolean(this.value.zip)) && (/(^\d{5}$)|(^\d{5}-\d{4}$)/.test(this.value.zip));
    },
    countryIsValid() {
      return (Boolean(this.value.country));
    },
    
  },

  methods: {
    update(key, value) {
      let tmpvalue2 = null;
      let tmpvalue1 = null;

      if (key === 'address_1') {
          if(this.value.address_line1 !== undefined) {
              delete this.value.address_line1;
            }
            if (this.value.address_line2 !== undefined && this.value.address_2 == undefined) {
                  tmpvalue2 = this.value.address_line2;
                  delete this.value.address_line2;
              }
            if (this.value.address_line2 !== undefined && this.value.address_2 !== undefined) { 
                  delete this.value.address_line2;
            }
            if (tmpvalue2 !== null) {
                this.$emit('input', { ...this.value, [key]:value, ['address_2']: tmpvalue2 })
            }
            else {
                this.$emit('input', { ...this.value, [key]: value })
            }
      }
      else if (key === 'address_2') {
          if(this.value.address_line2 !== undefined) {
              delete this.value.address_line2;
          }
              if (this.value.address_line1 !== undefined && this.value.address_1 == undefined) {
                  tmpvalue1 = this.value.address_line1;
                  delete this.value.address_line1;
                  
              }
              if (this.value.address_line1 !== undefined && this.value.address_1 !== undefined) { 
                  delete this.value.address_line1;
              }
              if (tmpvalue1 !== null) {
                this.$emit('input', { ...this.value, [key]:value, ['address_1']: tmpvalue1 })
            }
            else {
                this.$emit('input', { ...this.value, [key]: value })
            }
            
      }
      else 
      {
        this.$emit('input', { ...this.value, [key]: value })
      }
    },
    emitAddress() {
      this.$emit('input', this.value);
    },
    validateChildInputs() {
      return this.address1IsRequired && this.cityIsRequired && this.stateIsValid && this.zipIsValid && this.countryIsValid;
    }
  }
};
</script>

<style lang="scss" scoped>
  .invalid-input-message {
    color: red;
  }
</style>
