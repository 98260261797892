<template>
  <div>
    <ul class="list-group">
      <li class="list-group-item list-group-item-secondary">
        <h2>Directors</h2>
      </li>
      <li class="list-group-item" v-bind:key="'director-' + index" v-for="(director, index) in directorsLocal">
        <ContactShared v-if="contactShared" :contact="director" :contactShared="contactShared" />
        <div class="d-flex w-100 justify-content-between">
          <p class="font-weight-bold" v-if="director.company_name=='' || director.company_name==null">
            {{ director.first_name }} {{ director.middle_name }} {{ director.last_name }} {{ director.suffix }}
          </p>
          <p class="font-weight-bold" v-if="director.company_name!=''">
            {{ director.company_name }}
          </p>
          <small class="font-weight-bold">
            Director
            <button type="button" class="btn btn-danger btn-sm"  @click="removeDirector(index)"> Remove</button>
          </small>
        </div>
        <div class="row">
          <div class="col-md-6">
            <dt>Company Name</dt>
            <dd><input class="form-control" :value="director.company_name" @input="updateDirector(index, 'company_name', $event.target.value)"/></dd>
          </div>
          <div class="col-md-6">
            <dt>Titles</dt>
            <dd>
              <multiselect v-model="director.titleList" :options="contactTypesDisplayNames"
                 :searchable="true" :close-on-select="false" :show-labels="false" placeholder="Pick a value"
                  :multiple="true" @input="convertTitleListToCommaValues(director)">
                <span slot="noResult">Not found.</span>
              </multiselect>
            </dd>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <dt>First Name</dt>
            <dd><input class="form-control" :value="director.first_name" @input="updateDirector(index, 'first_name', $event.target.value)"/></dd>
          </div>
          <div class="col-md-6">
            <dt>Middle Name</dt>
            <dd><input class="form-control" :value="director.middle_name" @input="updateDirector(index, 'middle_name', $event.target.value)"/></dd>
          </div>
          <div class="col-md-6">
            <dt>Last Name</dt>
            <dd><input class="form-control" :value="director.last_name" @input="updateDirector(index, 'last_name', $event.target.value)"/></dd>
          </div>
          <div class="col-md-6">
            <dt>Suffix</dt>
            <select class="form-control" :value="director.suffix" @input="updateDirector(index, 'suffix', $event.target.value)">
              <option disabled>Select a suffix</option>
              <option value="">None</option>
              <option>DDS</option>
              <option>MD</option>
              <option>PhD</option>
              <option>Jr.</option>
              <option>Sr.</option>
              <option>I</option>
              <option>II</option>
              <option>III</option>
              <option>IV</option>
              <option>V</option>
              <option>VI</option>
            </select>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <dt>Phone</dt>
            <dd><input class="form-control" type="tel" :value="director.phone" @input="updateDirector(index, 'phone', $event.target.value)"/></dd>
          </div>
          <div class="col-md-6">
            <dt v-bind:class="{'text-danger': !validateEmail(director.email)}">Email</dt>
            <dd><input class="form-control" :value="director.email" @input="updateDirector(index, 'email', $event.target.value)"/></dd>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <dt>Address 1</dt>
            <dd><input class="form-control" :value="director.address_1" @input="updateDirector(index, 'address_1', $event.target.value)"/></dd>
          </div>
          <div class="col-md-6">
            <dt>Address 2</dt>
            <dd><input class="form-control" :value="director.address_2" @input="updateDirector(index, 'address_2', $event.target.value)"/></dd>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <dt>City</dt>
            <dd><input class="form-control" :value="director.city" @input="updateDirector(index, 'city', $event.target.value)"/></dd>
          </div>
          <div class="col-md-6">
            <dt>State</dt>
            <dd><input class="form-control" :value="director.state" @input="updateDirector(index, 'state', $event.target.value)"/></dd>
          </div>
          <div class="col-md-6">
            <dt>Zip</dt>
            <dd><input class="form-control" :value="director.zip" @input="updateDirector(index, 'zip', $event.target.value)"/></dd>
          </div>
          <div class="col-md-6">
            <dt>Country</dt>
            <dd><input class="form-control" :value="director.country" @input="updateDirector(index, 'country', $event.target.value)"/></dd>
          </div>
        </div>
      </li>
      <button @click="addDirector" type="button" class="btn btn-primary btn-square btn-block">+ Add Director</button>
      <!-- Officers -->
      <li class="list-group-item list-group-item-secondary">
        <h2>Officers</h2>
      </li>
      <li class="list-group-item" v-bind:key="'officer-' + index" v-for="(officer, index) in officersLocal">
        <ContactShared v-if="contactShared" :contact="officer" :contactShared="contactShared" />
        <div class="d-flex w-100 justify-content-between">
          <p class="font-weight-bold" v-if="officer.company_name=='' || officer.company_name==null">
            {{ officer.first_name }} {{ officer.middle_name }} {{ officer.last_name }} {{ officer.suffix }}
          </p>
          <p class="font-weight-bold" v-if="officer.company_name!=''">
            {{ officer.company_name }}
          </p>
          <small class="font-weight-bold">
            Officer
            <button type="button" class="btn btn-danger btn-sm"  @click="removeOfficer(index)"> Remove</button>
          </small>
        </div>
        <div class="row">
          <div class="col-md-6">
            <dt>Company Name</dt>
            <dd><input class="form-control" :value="officer.company_name" @input="updateOfficer(index, 'company_name', $event.target.value)"/></dd>
          </div>
          <div class="col-md-6">
            <dt>Titles</dt>
            <dd>
              <multiselect v-model="officer.titleList" :options="contactTypesDisplayNames"
                 :searchable="true" :close-on-select="false" :show-labels="false" placeholder="Pick a value"
                  :multiple="true" @input="convertTitleListToCommaValues(officer)">
                <span slot="noResult">Not found.</span>
              </multiselect>
            </dd>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <dt>First Name</dt>
            <dd><input class="form-control" :value="officer.first_name" @input="updateOfficer(index, 'first_name', $event.target.value)"/></dd>
          </div>
          <div class="col-md-6">
            <dt>Middle Name</dt>
            <dd><input class="form-control" :value="officer.middle_name" @input="updateOfficer(index, 'middle_name', $event.target.value)"/></dd>
          </div>
          <div class="col-md-6">
            <dt>Last Name</dt>
            <dd><input class="form-control" :value="officer.last_name" @input="updateOfficer(index, 'last_name', $event.target.value)"/></dd>
          </div>
          <div class="col-md-6">
            <dt>Suffix</dt>
            <select class="form-control" :value="officer.suffix" @input="updateOfficer(index, 'suffix', $event.target.value)">
              <option disabled>Select a suffix</option>
              <option value="">None</option>
              <option>DDS</option>
              <option>MD</option>
              <option>PhD</option>
              <option>Jr.</option>
              <option>Sr.</option>
              <option>I</option>
              <option>II</option>
              <option>III</option>
              <option>IV</option>
              <option>V</option>
              <option>VI</option>
            </select>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <dt>Phone</dt>
            <dd><input class="form-control" type="tel" :value="officer.phone" @input="updateOfficer(index, 'phone', $event.target.value)"/></dd>
          </div>
          <div class="col-md-6">
            <dt v-bind:class="{'text-danger': !validateEmail(officer.email)}">Email</dt>
            <dd><input class="form-control" :value="officer.email" @input="updateOfficer(index, 'email', $event.target.value)"/></dd>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <dt>Address 1</dt>
            <dd><input class="form-control" :value="officer.address_1" @input="updateOfficer(index, 'address_1', $event.target.value)"/></dd>
          </div>
          <div class="col-md-6">
            <dt>Address 2</dt>
            <dd><input class="form-control" :value="officer.address_2" @input="updateOfficer(index, 'address_2', $event.target.value)"/></dd>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <dt>City</dt>
            <dd><input class="form-control" :value="officer.city" @input="updateOfficer(index, 'city', $event.target.value)"/></dd>
          </div>
          <div class="col-md-6">
            <dt>State</dt>
            <dd><input class="form-control" :value="officer.state" @input="updateOfficer(index, 'state', $event.target.value)"/></dd>
          </div>
          <div class="col-md-6">
            <dt>Zip</dt>
            <dd><input class="form-control" :value="officer.zip" @input="updateOfficer(index, 'zip', $event.target.value)"/></dd>
          </div>
          <div class="col-md-6">
            <dt>Country</dt>
            <dd><input class="form-control" :value="officer.country" @input="updateOfficer(index, 'country', $event.target.value)"/></dd>
          </div>
        </div>
      </li>
      <button @click="addOfficer" type="button" class="btn btn-primary btn-square btn-block">+ Add Officer</button>
    </ul>
  </div>
</template>
<script>

import { inputValidationsMixin } from '../../mixins';
import ContactShared from '@/components/common/ContactShared';

export default {
  name: 'OfficerDirectorEdit',

  components: {
    ContactShared
  },

  mixins: [inputValidationsMixin],

  props: ['officers', 'directors', 'contactTypes', 'contactShared'],

  computed: {
    officersLocal() {
      return this.officers ? this.officers : []
    },
    directorsLocal() {
      return this.directors ? this.directors : []
    },
    contactTypesDisplayNames() {
      return this.contactTypes.map((x) => x.display_name);
    }
  },

  methods: {
    emitOfficers() {
      this.$emit('update:officers', this.officersLocal);
    },
    emitDirectors() {
      this.$emit('update:directors', this.directorsLocal);
    },
    addDirector() {
      if (this.directorsLocal === null) {
        this.directorsLocal = [];
      }
      this.directorsLocal.push({});
      this.emitDirectors()
    },
    removeDirector(index) {
      this.directorsLocal.splice(index, 1);
      this.emitDirectors()
    },
    updateDirector(index, key, value) {
      this.directorsLocal[index] = { ...this.directorsLocal[index], [key]: value }
      this.emitDirectors();
    },
    addOfficer() {
      if (this.officersLocal === null) {
        this.officersLocal = [];
      }
      this.officersLocal.push({});
      this.emitOfficers()
    },
    removeOfficer(index) {
      this.officersLocal.splice(index, 1);
      this.emitOfficers()
    },
    updateOfficer(index, key, value) {
      this.officersLocal[index] = { ...this.officersLocal[index], [key]: value }
      this.emitOfficers();
    },
    convertTitleListToCommaValues(contact) {
      contact.title = contact.titleList.join(',');
      return contact.titleList;
    }
  }
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"/>

<style lang="scss" scoped>
.list-group {
  margin-top: 15px;
}
h2 {
  font-size: 10pt;
}
.list-group-item-secondary{
  background-color: #a9a9a9;
  color: #ffffff;
}
.btn-square {
  border-radius: 0px;
}
</style>
