<template>
  <div>
    <div class="row">
      <div class="col-md-2">
        <div id="business-entity-menu" class="menu sticky-top">
          <h3>Actions</h3>
          <ZgButton class="zg-btn-info zg-btn-sm" @click="handleSaveClick" :isEnable="!isLoading">
            <template v-slot:title>Save</template>
          </ZgButton>
          <ZgButton class="zg-btn-dark zg-btn-sm" @click="handleCancelClick">
            <template v-slot:title>Cancel</template>
          </ZgButton>
        </div>
      </div>
      <div class="col-md-10 position-relative" :aria-busy="`${isLoading}`">
        <LoadingWrapper :isLoading="isLoading" />
        <form>
          <h2 class="header muted">Edit Filing Info</h2>
          <div class="box">
            <div class="row">
              <div class="col">
                <dt>Entity Name</dt>
                <dd><input class="form-control" v-model.trim="entityName"/></dd>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <dt>Business Purpose</dt>
                <dd><textarea class="form-control" v-model.trim="businessPurpose" /></dd>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <dt>EIN</dt>
                <dd><input class="form-control" v-model.trim="ein" maxlength="20"/></dd>
              </div>
              <div class="col-md-4">
                <dt>Business Industry</dt>
                <dd><input class="form-control" v-model.trim="businessIndustry" /></dd>
              </div>
              <div class="col-md-4">
                <dt>Entity Type</dt>
                <dd>
                  <select class="form-control" v-model="businessEntityType">
                    <option disabled value="">Select an entity type</option>
                    <option value="llc">LLC</option>
                    <option value="corp">CORPORATION</option>
                  </select>
                </dd>
              </div>
            </div>
            <br/>
            <div class="row">
              <div class="col-md-6">
                <dt>Authorized Signatory</dt>
              </div>
            </div>
            <br/>
            <div class="row" v-if="possibleAuthorizedSignatories.length">
              <div class="col-md-8">
                <dt>Person</dt>
                <dd>
                  <select class="form-control" v-model="authorizedSignatoryMember">
                    <option v-for="person in possibleAuthorizedSignatories.map(this.computeFullNameOfPerson)" v-bind:key="person" v-bind:value="person">{{ person }}</option>
                  </select>
                </dd>
              </div>
              <div class="col-md-4">
                <dt>Title</dt>
                <dd>
                  <select class="form-control" v-model="authorizedSignatory.title">
                    <option disabled>Select a title</option>
                    <option v-bind:key="index" v-for="(contactType, index) in contactTypes" :value="contactType.display_name">{{contactType.display_name}}</option>
                  </select>
                </dd>
              </div>
            </div>
            <div v-else-if="authorizedSignatory">
              <div class="row">
                <div class="col-md-3">
                  <dt>First Name</dt>
                  <dd>
                    <input class="form-control" v-model.trim="authorizedSignatory.first_name" />
                  </dd>
                </div>
                <div class="col-md-3">
                  <dt>Middle Name</dt>
                  <dd>
                    <input class="form-control" v-model.trim="authorizedSignatory.middle_name" />
                  </dd>
                </div>
                <div class="col-md-3">
                  <dt>Last Name</dt>
                  <dd>
                    <input class="form-control" v-model.trim="authorizedSignatory.last_name" />
                  </dd>
                </div>
                <div class="col-md-3">
                  <dt>Suffix</dt>
                  <dd>
                    <select class="form-control" v-model="authorizedSignatory.suffix">
                      <option disabled>Select a suffix</option>
                      <option value="">None</option>
                      <option>DDS</option>
                      <option>MD</option>
                      <option>PhD</option>
                      <option>Jr.</option>
                      <option>Sr.</option>
                      <option>I</option>
                      <option>II</option>
                      <option>III</option>
                      <option>IV</option>
                      <option>V</option>
                      <option>VI</option>
                    </select>
                  </dd>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4">
                  <dt>Title</dt>
                  <dd>
                    <select class="form-control" v-model="authorizedSignatory.title">
                      <option disabled>Select a title</option>
                      <option v-bind:key="index" v-for="(contactType, index) in contactTypes" :value="contactType.display_name">{{contactType.display_name}}</option>
                    </select>
                  </dd>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <dt>NAICS Code</dt>
                <dd>
                  <select class="form-control" v-model="naicsShortCode" @change="refreshSubcodes">
                    <option :value="null">Select None</option>
                    <option v-for="code in shortCodeOptions" v-bind:key="code.value" :value="code.value">{{ code.value }} ({{ code.label }})</option>
                  </select>
                </dd>
              </div>
              <div class="col-md-4">
                <dt>NAICS Subcode</dt>
                <dd>
                  <select class="form-control" v-model="naicsCode">
                    <option v-for="code in naicsCodeOptions" v-bind:key="code.value" :value="code.value">{{ code.value }} ({{ code.label }})</option>
                  </select>
                </dd>
              </div>
            </div>
            <template v-if="isCorp">
              <hr/>
              <div class="row">
                <div class="col-md-4">
                  <dt>Authorized Shares</dt>
                  <dd><input class="form-control" v-model.trim="authorizedShares"/></dd>
                </div>
                <div class="col-md-4">
                  <dt>Par Value</dt>
                  <dd><input class="form-control" v-model.trim="parValue"/></dd>
                </div>
                <div class="col-md-4">
                  <dt>Has more than one share</dt>
                  <dd><input type="checkbox" v-model="hasMoreThanOneShare" /></dd>
                </div>
              </div>
            </template>

            <hr/>
            <div v-for="(jurisdiction, index) in jurisdictions" v-bind:key="jurisdiction.jurisdiction_code" v-bind:class="jurisdictionClass(jurisdiction)">
              <div class="row">
                <div class="col">
                  <dt>Jurisdiction</dt>
                  <dd v-if="jurisdiction.jurisdiction_uuid">{{ jurisdiction.jurisdiction_code }}</dd>
                  <dd v-else>
                    <select class="form-control" v-model="jurisdiction.jurisdiction_code">
                      <option disabled value="">Please select one</option>
                      <option v-for="jur in allJurisdictions" v-bind:key="jur">{{ jur }}</option>
                    </select>
                  </dd>
                </div>
                <div class="col">
                  <dt>Domestic?</dt>
                  <dd>
                    <input type="checkbox" v-model="jurisdiction.domestic_flag" />
                  </dd>
                </div>
                <div class="col">
                  <dt>Jurisdiction Entity Name</dt>
                  <dd>
                    <input class="form-control" v-model.trim="jurisdiction.jurisdiction_entity_name"
                           :disabled="!canEditJurisdictionEntityName(jurisdiction)"/>
                  </dd>
                </div>
                <div class="col">
                  <dt>Has forced name?</dt>
                  <dd>
                    <input type="checkbox" v-model="jurisdiction.has_forced_name" />
                  </dd>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <dt>Business ID</dt>
                  <dd><input class="form-control" v-model.trim="jurisdiction.jurisdiction_filing_number" /></dd>
                </div>
                <div class="col">
                  <dt>Filing Date</dt>
                  <span v-if="showFutureFilingDateError(jurisdiction)" class="text-danger">Filing date and Future filing date should be equal</span>
                  <dd>
                    <datepicker name="date" v-model.trim="jurisdiction.jurisdiction_filing_date" :format="datePicker.format" :use-utc="datePicker.use_utc" :input-class="datePicker.date_picker_class"></datepicker>
                  </dd>
                </div>
                <div class="col">
                  <dt>Customer ID</dt>
                  <dd>
                    <input class="form-control" v-model.trim="jurisdiction.customer_id" />
                  </dd>
                </div>
                <div class="col">
                  <dt>State PIN</dt>
                  <dd>
                    <input class="form-control" v-model.trim="jurisdiction.pin" />
                  </dd>
                </div>
                <div class="col">
                  <dt>Other Credential</dt>
                  <dd>
                    <input class="form-control" v-model.trim="jurisdiction.other_credential" />
                  </dd>
                </div>
                <div class="col" >
                  <button v-if="isMarkedForRemoval(jurisdiction)" @click="undoRemoveJurisdiction(jurisdiction.jurisdiction_uuid)" type="button" class="btn btn-warning btn-sm">Undo</button>
                  <button v-else @click="removeJurisdiction(index)" type="button" class="btn btn-danger btn-sm">Remove</button>
                </div>
              </div>
              <div class="row" v-if="jurisdiction.jurisdiction_code === 'CA'">
                <div class="col-md-4">
                  <dt>Name reservation?</dt>
                  <dd>
                    <input type="checkbox" v-model="jurisdiction.has_name_reservation" />
                  </dd>
                </div>
                <div class="col-md-4">
                  <dt>Name reservation number</dt>
                  <dd><input class="form-control" v-model.trim="jurisdiction.name_reservation_number" :disabled="!jurisdiction.has_name_reservation" maxlength="12"/></dd>
                </div>
              </div>
              <div class="row" v-if="jurisdiction.jurisdiction_code === 'CA'">
                <div class="col">
                  <dt>Elective CEO</dt>
                  <dd>
                    <address-with-name-edit :address="jurisdiction.extra_state_info.elective_ceo" />
                  </dd>
                </div>
              </div>
              <hr v-if="index < jurisdictions.length - 1" />
            </div>
            <button @click="addJurisdiction" type="button" class="btn btn-primary btn-square btn-block mb-4">+ Add Jurisdiction</button>

            <hr />
            <div v-for="(fka, index) in entityFkas" v-bind:key="index">
              <div class="row" v-if="!fka.deleted">
                <div class="col-9">
                  <dt>FKA Name {{fka.deleted}}</dt>
                  <dd>
                    <input type="text" class="form-control"  v-model.trim="fka.fka_name">
                  </dd>
                </div>
                <div class="col-3">
                  <dt>Action</dt>
                  <dd>
                    <button @click="removeFKA(index)" type="button" class="btn btn-danger mt-0">Remove</button>
                  </dd>
                </div>
              </div>
            </div>
            <button @click="addFKA" type="button" class="btn btn-primary btn-square btn-block">+ Add FKA</button>
          </div>

          <h2 class="header muted">Edit Management Info</h2>
          <div class="box">
            <div class="row">
              <div v-if="isLLC" class="col-md-12">
                <dt>Management Structure</dt>
                <select class="form-control" v-model="managerType">
                  <option disabled>Select file category</option>
                  <option value="all_owners">All the owners (Member managed)</option>
                  <option value="some_owners">Only some of the owners (Manager managed)</option>
                  <option value="some_managers">Some or all of the owners and other managers (Manager managed)</option>
                  <option value="all_managers">None of the owners, only other managers (Manager managed)</option>
                </select>

                <member-manager-edit
                  :members="members" @update:members="members = $event"
                  :managers="managers" @update:managers="managers = $event"
                  :contactShared="contactShared"
                />
              </div>
              <div v-if="!isLLC" class="col-md-12">
                <OfficerDirectorEdit
                  :officers="officers" @update:officers="officers = $event"
                  :directors="directors"  @update:directors="directors = $event"
                  :contactTypes="contactTypes"
                  :contactShared="contactShared"
                />
              </div>

            </div>
          </div>

          <h2 class="header muted">Edit Business Physical Address</h2>
          <address-edit v-model="address" ref="EditAddress"/>

          <h2 class="header muted">Edit Business Mailing Address</h2>
          <div class="btn-sm btn-info" @click="copyToMailingAddress">Copy from physical address</div>
          <address-edit v-model="mailingAddress" ref="EditMailingAddress"/>

          <h2 class="header muted">Edit Business Principal Address</h2>
          <div class="btn-sm btn-info" @click="copyToPrincipalAddress">Copy from physical address</div>
          <address-edit v-model="principalAddress" ref="EditPrincipalAddress"/>

          <h2 class="header muted">Edit Contacts</h2>
          <div class="box">
            <div>
              <h2 class="header muted">Edit Contact</h2>
              <address-with-name-edit v-model="contact" ref="EditContact" :isValidationAddress="true" :contactShared="contactShared"/>
            </div>
            <div>
              <h2 class="header muted">Edit Service of Process Contact</h2>
              <div class="btn-sm btn-info" @click="copyToSopAddress">Copy from contact</div>
              <address-with-name-edit v-model="sop_contact" ref="EditSOFContact" :isValidationAddress="false" :contactShared="contactShared"/>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex';
import Datepicker from 'vuejs-datepicker';
import AddressEdit from '@/components/common/forms/address-edit';
import AddressWithNameEdit from '@/components/common/forms/address-with-name-edit';
import LoadingWrapper from '@/components/common/LoadingWrapper';
import MemberManagerEdit from '@/components/common/forms/member-manager-edit';
import messages from '../../assets/messages';
import OfficerDirectorEdit from '@/components/common/forms/officer-director-edit';
import { computeFullNameOfPersonMixin, naicsCodesMixins, hasPermissionMixin } from '@/components/mixins';
import { US_JURISDICTIONS } from '../../assets/jurisdictions';
import ZgButton from "@/components/common/Generics/ZgButton";
import authService from '../../auth/AuthService';
import { generateContactSharedMap } from '@/utils/contact-types';


export default {
  name: 'BusinessEntityEdit',

  components: {
    Datepicker,
    AddressEdit,
    AddressWithNameEdit,
    LoadingWrapper,
    MemberManagerEdit,
    OfficerDirectorEdit,
    ZgButton
  },

  mixins: [computeFullNameOfPersonMixin, naicsCodesMixins, hasPermissionMixin],

  computed: {
    ...mapState({
      jurisdictions: state => state.businessEntities.jurisdictions,
      contactTypes: state => state.businessEntities.contactTypes,
      contacts: state => state.businessEntities.contacts,
      businessEntity: state => state.businessEntities.businessEntity,
      businessEntityTasks: state => state.businessEntities.businessEntityTasks,
      fkas: (state) => state.businessEntities.fkas,
      sopContact: state => state.businessEntities.sopContact,
    }),
    ...mapGetters({
      getBusinessEntityUuid: 'businessEntities/getBusinessEntityUuid'
    }),
    contactShared: {
      get() {
        return generateContactSharedMap(this.contacts);
      }
    },
    entityName: {
      get() {
        return this.businessEntity.business_entity_name;
      },
      set(value) {
        this.$store.commit('businessEntities/setEntityName', value);
      }
    },
    businessEntityType: {
      get() {
        return this.businessEntity.business_entity_type;
      },
      set(businessEntityType) {
        this.$store.commit('businessEntities/setEntityType', businessEntityType);
      }
    },
    isLLC: {
      get() {
        return this.businessEntity.business_entity_type === 'llc';
      }
    },
    isSoleProp: {
      get() {
        return this.businessEntity.business_entity_type === 'sole_prop';
      }
    },
    isCorp: {
      get() {
        return this.businessEntity.business_entity_type === 'corp';
      }
    },
    ein: {
      get() {
        return this.businessEntity.business_entity_data.ein;
      },
      set(value) {
        this.$store.commit('businessEntities/setEin', value);
      }
    },
    businessPurpose: {
      get() {
        return this.businessEntity.business_entity_data.business_purpose;
      },
      set(value) {
        this.$store.commit('businessEntities/setBusinessPurpose', value);
      }
    },
    businessIndustry: {
      get() {
        return this.businessEntity.business_entity_data.business_industry;
      },
      set(value) {
        this.$store.commit('businessEntities/setBusinessIndustry', value);
      }
    },
    authorizedSignatoryMember: {
      // Data is stored as a person object, but the options show the full name of the person
      get() {
        if (this.businessEntity.business_entity_data.authorized_signatory) {
          return this.computeFullNameOfPerson(this.businessEntity.business_entity_data.authorized_signatory);
        }
        return '';
      },
      set(fullName) {
        this.matchPersonObjectByFullName(fullName);
      }
    },
    authorizedSignatory: {
      get() {
        return this.businessEntity.business_entity_data.authorized_signatory;
      }
    },
    registeredAgent: {
      get() {
        return this.businessEntity.business_entity_data.registered_agent;
      }
    },
    managerType: {
      get() {
        return this.businessEntity.business_entity_data.manager_type;
      },
      set(value) {
        this.$store.commit('businessEntities/setManagerType', value);
      }
    },
    authorizedShares: {
      get() {
        return this.businessEntity.business_entity_data.authorized_shares;
      },
      set(value) {
        this.$store.commit('businessEntities/setAuthorizedShares', value);
      }
    },
    parValue: {
      get() {
        return this.businessEntity.par_value;
      },
      set(value) {
        this.$store.commit('businessEntities/setParValue', value);
      }
    },
    hasMoreThanOneShare: {
      get() {
        return this.businessEntity.has_more_than_one_share;
      },
      set(value) {
        this.setHasMoreThanOneShare(value);
      }
    },
    filingDate: {
      get() {
        return this.$store.state.businessEntities.jurisdictions[0].jurisdiction_filing_date;
      },
      set(value) {
        this.$store.commit('businessEntities/setFilingDate', value);
      }
    },
    filingNumber: {
      get() {
        return this.$store.state.businessEntities.jurisdictions[0].jurisdiction_filing_number;
      },
      set(value) {
        this.$store.commit('businessEntities/setFilingNumber', value);
      }
    },
    contact: {
      get() {
        return this.businessEntity.business_entity_data.contact;
      },
      set(contact) {
        this.businessEntity.business_entity_data.contact = contact
        
      }
    },
    sop_contact: {
      get() {
        // There will only be sop contact
        if (this.sopContact.length > 0) {
        return this.sopContact[0]
      }
      return []
      },
      set(value) {
        this.$store.commit('businessEntities/setSopContact', [value]);
      }
    },
    address: {
      get() {
        const address = this.$store.state.businessEntities.addresses.find((a) => a.address_type === 'physical');
        return address ? address.address : {};
      },
      set(addr) {
        const addressBlob = { address_type: 'physical', address: addr };
        this.$store.state.businessEntities.addresses = this.$store.state.businessEntities.addresses.filter((a) => a.address_type !== 'physical').concat([addressBlob]);
      }
    },
    mailingAddress: {
      get() {
        const mailingAddress = this.$store.state.businessEntities.addresses.find((a) => a.address_type === 'mailing');
        return mailingAddress ? mailingAddress.address : {};
      },
      set(addr) {
        const addressBlob = { address_type: 'mailing', address: addr };
        this.$store.state.businessEntities.addresses = this.$store.state.businessEntities.addresses.filter((a) => a.address_type !== 'mailing').concat([addressBlob]);
      }
    },
    principalAddress: {
      get() {
        const principalAddress = this.$store.state.businessEntities.addresses.find((a) => a.address_type === 'principal');
        return principalAddress ? principalAddress.address : {};
      },
      set(addr) {
        const addressBlob = { address_type: 'principal', address: addr };
        this.$store.state.businessEntities.addresses = this.$store.state.businessEntities.addresses.filter((a) => a.address_type !== 'principal').concat([addressBlob]);
      }
    },
    entityFkas: {
      get() {
        return this.fkas;
      },
      set(value) {
        this.$store.commit('businessEntities/setBusinessEntityFKAs', value);
      }
    },
    hasRA: {
      get() {
        return this.businessEntity.has_ra;
      }
    },
    members: {
      get() {
        if (this.isLLC && this.businessEntity.business_entity_data.members) {
          return this.businessEntity.business_entity_data.members;
        }
        return null;
      },
      set(value) {
        this.$store.commit('businessEntities/setBusinessEntityMembers', value);
      }
    },
    managers: {
      get() {
        if (this.isLLC && this.businessEntity.business_entity_data.managers) {
          return this.businessEntity.business_entity_data.managers;
        }
        return null;
      },
      set(value) {
        this.$store.commit('businessEntities/setBusinessEntityManagers', value);
      }
    },
    officers: {
      get() {
        if (!this.isLLC
          && this.businessEntity.business_entity_data.officers) {
          return this.businessEntity.business_entity_data.officers;
        }
        return null;
      },
      set(value) {
        this.$store.commit('businessEntities/setBusinessEntityOfficers', value);
      }
    },
    directors: {
      get() {
        if (!this.isLLC
          && this.businessEntity.business_entity_data.directors) {
          return this.businessEntity.business_entity_data.directors;
        }
        return null;
      },
      set(value) {
        this.$store.commit('businessEntities/setBusinessEntityDirectors', value);
      }
    },
    userEmail() {
      return authService.getLoggedInEmail() || '';
    },
    possibleAuthorizedSignatories() {
      // Valid candidates for the authorized signatories are:
      // * managers of an LLC (can be member-managers)
      // * directors and officers of a corporation
      // * people, not companies
      if (this.isLLC) {
        const managers = this.$store.state.businessEntities.businessEntity.business_entity_data.managers || [];
        const members = this.$store.state.businessEntities.businessEntity.business_entity_data.members || [];
        let memberManagers = [];
        const explicitMemberManagers = members.filter((member) => member.manager);
        // If there are no managers, and no members with "manager: true", then all members are managers
        if (explicitMemberManagers.length === 0 && managers.length === 0) {
          memberManagers = members;
        } else {
          memberManagers = explicitMemberManagers;
        }
        return managers.concat(memberManagers).filter((candidate) => !candidate.company_name);
      }
      const officers = this.$store.state.businessEntities.businessEntity.business_entity_data.officers || [];
      const directors = this.$store.state.businessEntities.businessEntity.business_entity_data.directors || [];
      return officers.concat(directors).filter((candidate) => !candidate.company_name);
    },
    naicsCode: {
      get() {
        return this.businessEntity.naics_code;
      },
      set(value) {
        this.$store.commit('businessEntities/setBusinessNaicsCode', value);
      }
    },
    naicsShortCode: {
      get() {
        return this.selectedNAICSShortCode ? this.selectedNAICSShortCode : this.naicsCode ? this.naicsCode.substring(0, 2) : null;
      },
      set(value) {
        this.selectedNAICSShortCode = value;
      }
    },
    shortCodeOptions() {
      return this.getShortCodeOptions();
    },
    naicsCodeOptions() {
      return this.getCodeOptions(this.naicsShortCode);
    },
    allJurisdictions() {
      return US_JURISDICTIONS;
    }
  },

  methods: {
    handleSaveClick() {
      const validationErrors = this.validateData();
      if (validationErrors) {
        const errorAlertData = {
          primary: validationErrors,
          type: 'alert alert-danger'
        };
        this.$store.commit('setAlertMessage', errorAlertData, { root: true });
        return;
      }
      this.formatAuthorizedShares();
      this.compareEntityJurisdictionName();
      if (!this.hasRA) {
        this.$store.commit('businessEntities/setBusinessEntityRegisteredAgent', this.registeredAgent);
      } else {
        this.$store.commit('businessEntities/setBusinessEntityExternalRaCompanyId', this.businessEntity.external_ra_company_id);
      }
      if (this.isLLC) {
        this.$store.commit('businessEntities/setBusinessEntityMembers', this.members);
        this.$store.commit('businessEntities/setBusinessEntityManagers', this.managers);
      } else {
        this.$store.commit('businessEntities/setBusinessEntityOfficers', this.officers);
        this.$store.commit('businessEntities/setBusinessEntityDirectors', this.directors);
      }

      // Use authorizedSignatoryMember that is only set on select field change, otherwise use authorizedSignatory that comes from business_entity_data
      const authSigFullName = this.computeFullNameOfPerson(this.authorizedSignatoryMember) || this.computeFullNameOfPerson(this.authorizedSignatory);
      this.matchPersonObjectByFullName(authSigFullName);

      this.$store.commit('businessEntities/setBusinessEntityUpdatedBy', this.userEmail);
      this.$store.commit('businessEntities/setBusinessEntityAddress', this.address);
      if (Object.keys(this.mailingAddress).length > 0) {
        this.$store.commit('businessEntities/setBusinessEntityMailingAddress', this.mailingAddress);
      }
      if (Object.keys(this.principalAddress).length > 0) {
        this.$store.commit('businessEntities/setBusinessEntityPrincipalAddress', this.principalAddress);
      }
      this.$store.commit('businessEntities/setBusinessEntityContact', this.contact);
      this.$store.commit('businessEntities/setBusinessEntitySopContact', this.sop_contact);
      this.$store.commit('businessEntities/setJurisdictions', this.jurisdictions.filter((j) => !this.isMarkedForRemoval(j)));
      this.$store.dispatch('businessEntities/updateBusinessEntity')
        .then(() => {
          this.$store.dispatch('businessEntities/updateFKAs', { uuid: this.getBusinessEntityUuid });
          this.$store.dispatch('businessEntities/removeJurisdictions', { jurisdictionUuids: this.jurisdictionsToRemove });
          this.$store.dispatch('businessEntities/updateJurisdictions')
            .then(() => {
              this.$store.dispatch('businessEntities/fetchBusinessEntity', { uuid: this.getBusinessEntityUuid });
              this.$router.push({ name: 'BusinessEntity', params: { businessEntityUuid: this.getBusinessEntityUuid } });
            });
        });
    },
    validateData() {
      const domesticJurisdictions = this.jurisdictions.filter((jur) => jur.domestic_flag);
      let errorMessages = [];
      if (!this.$refs.EditAddress.validateChildInputs()) {
          errorMessages.push('Incomplete Physical Address.');
      }
      if (!this.$refs.EditMailingAddress.validateChildInputs()) {
          errorMessages.push('Incomplete Mailing Address.');
      }
      if (!this.$refs.EditPrincipalAddress.validateChildInputs()) {
          errorMessages.push('Incomplete Principal Address.');
      }
      if (!this.$refs.EditContact.validateChildInputs()) {
          errorMessages.push('Incomplete Contact.');
      } 
      if (!this.$refs.EditSOFContact.validateChildInputsOptional()) {
          errorMessages.push('Incomplete SOP Contact.');
      } 
      if (domesticJurisdictions.length > 1) {
        errorMessages.push('A business entity may only have one domestic jurisdiction.');
      }

      if (errorMessages.length > 0) {
        return errorMessages.join(' ');
      }

      return '';
    },
    formatAuthorizedShares() {
      if (this.authorizedShares && typeof this.authorizedShares === "string") {
        const shares = parseInt(this.authorizedShares.replace(/,/g, ""), 10);
        this.setAuthorizedShares(shares);
      }
    },
    handleCancelClick() {
      this.$store.dispatch('businessEntities/fetchBusinessEntity', { uuid: this.getBusinessEntityUuid })
        .then(() => {
          this.$router.push({ name: 'BusinessEntity', params: { businessEntityUuid: this.getBusinessEntityUuid } });
        });
    },
    copyToMailingAddress() {
      // Handle the case where the address dict is completely absent by first assigning it to an empty dict
      this.mailingAddress = {};
      Object.assign(this.mailingAddress, this.address);
    },
    copyToPrincipalAddress() {
      // Handle the case where the address dict is completely absent by first assigning it to an empty dict
      this.principalAddress = {};
      Object.assign(this.principalAddress, this.address);
    },
    copyToSopAddress() {
      // Handle the case where the address dict is completely absent by first assigning it to an empty dict
      this.sop_contact = {};
      Object.assign(this.sop_contact, this.contact);
    },
    refreshSubcodes() {
      if (this.selectedNAICSShortCode) {
        this.naicsCode = this.naicsCodeOptions[0].value;
      } else {
        this.naicsCode = null;
      }
    },
    addJurisdiction() {
      this.jurisdictions.push({ domestic_flag: false, extra_state_info: {} });
    },
    removeJurisdiction(index) {
      const toRemove = this.jurisdictions[index];
      if (toRemove.jurisdiction_uuid) {
        this.jurisdictionsToRemove.push(toRemove.jurisdiction_uuid);
      } else {
        this.jurisdictions = this.jurisdictions.splice(index, 1);
      }
    },
    undoRemoveJurisdiction(jurisdictionUuid) {
      this.jurisdictionsToRemove = this.jurisdictionsToRemove.filter((j) => j !== jurisdictionUuid);
    },
    jurisdictionClass(jurisdiction) {
      return { markedForRemoval: this.isMarkedForRemoval(jurisdiction) };
    },
    isMarkedForRemoval(jurisdiction) {
      return jurisdiction.jurisdiction_uuid && this.jurisdictionsToRemove.includes(jurisdiction.jurisdiction_uuid);
    },
    addFKA() {
      this.entityFkas.push({ fka_name: '' });
    },
    removeFKA(index) {
      const newFKA = { ...this.entityFkas[index], deleted: true };
      this.entityFkas = this.entityFkas.map((item, idx) => (index === idx ? newFKA : item));
    },
    showFutureFilingDateError(jurisdiction) {
      const jurisdictionFilingDate = jurisdiction.jurisdiction_filing_date;
      const futureFilingDate = jurisdiction.future_filing_date;
      if (jurisdictionFilingDate && futureFilingDate && jurisdictionFilingDate !== futureFilingDate) {
        return true;
      }
      return false;
    },
    canEditJurisdictionEntityName (jurisdiction) {
      return (this.hasPermission('write:jurisdictions') || !jurisdiction.jurisdiction_uuid) && jurisdiction.has_forced_name
    },
    canEditRAtoZBRegisterAgent () {
      return this.hasPermission('access:zb-registered-agent')
    },
    matchPersonObjectByFullName(fullName) {
      // Match to the person object by computed full name
      const candidates = this.possibleAuthorizedSignatories;
      for (const candidate of candidates) {
        const candidateName = this.computeFullNameOfPerson(candidate);
        if (fullName === candidateName) {
          this.$store.commit('businessEntities/setAuthorizedSignatory', candidate);
          break;
        }
      }
    },
    /** Method that validates if the business entity name is different from a given 
     * Jurisdiction name, to ensure that if it has been modified, it will be safe
     * to send has_forced_name as true.
     * To pass the data validation from JurisdictionSerializer
   */
    compareEntityJurisdictionName(){
      this.jurisdictions.map((jurisdiction) => {
        if(this.entityName !== jurisdiction.jurisdiction_entity_name) {
          jurisdiction.has_forced_name = true;
        } else {
          jurisdiction.has_forced_name = false;
        }
      });
    },
    ...mapActions("businessEntities", [
      'fetchBusinessEntity',
      "setHasMoreThanOneShare",
      "setAuthorizedShares",
    ]),
    ...mapActions({
      setAlertMessage: 'setAlertMessage',
      fetchSopContact: 'businessEntities/fetchSopContact',
    })
  },
  data() {
    return {
      isLoading: true,
      datePicker: {
        format: "yyyy-MM-dd",
        use_utc: true,
        date_picker_class: "form-control"
      },
      selectedNAICSShortCode: null,
      jurisdictionsToRemove: [],
    };
  },
  created () {
    this.fetchBusinessEntity({ uuid: this.getBusinessEntityUuid })
      .then(() => {
        this.isLoading = false
      })
      .catch(() => {
        this.setAlertMessage({ message: messages.fetchBusinessEntityMsg.error })
        setTimeout(() =>  {
            this.$router.go(-1)
          }, 5000)
        }
      )
  },
  mounted() {
    this.fetchSopContact({ businessEntityUUID: this.getBusinessEntityUuid });
  },
};
</script>

<style lang="scss" scoped>
h3 {
  font-size: 10pt;
  margin: 5px 0 0 0;
}
#business-entity-menu {
  display: flex;
  flex-direction: column;
  // Offset the sticky "Actions" panel so that it stays below the sticky navbar
  top: 75px;
}
.menu {
  border: solid 1px #dee2e6;
  border-radius: 5px;
  padding: 10px;
}
div .btn {
  width: 100%;
  margin-top: 10px;
}
.markedForRemoval {
  background-color: #f8d7db !important;
}
</style>
